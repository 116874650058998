import { ThemeProvider } from 'styled-components';

const colorTheme = {
  color: {
    primary: '#FFFFFF',
    secondary: '#2D3142',
    background: '#2D3142',
    icon: '#468C98',
    secondaryBackground: '#000000',
    accent: '#EF8354',
    buttonHover: '#9F7065',
    lightPrimary: 'rgba(70, 140, 152, 0.3)',
    hoverPrimary: 'rgba(70, 140, 152, 0.5)',
  }
}
const themeObject = {
  font: {
    size: {
      extraSmall: '14px',
      small: '16px',
      medium: '18px',
      large: '20px',
      extraLarge: '24px',
      titleLarge: '42px',
      mobileLarge: '30px',
    },
    family: 'sans-serif',
    main: 'Roboto',
    logo: 'Abril Fatface, cursive',
  },
  sizes: {
    headerHeight: '50px',
  },
  breakpoint: {
    mobile: '375px',
    tablet: '600px',
    laptop: '1200px',
    desktop: '1600px',
  },
};

const Theme = ({ children }) => {
  const theme = {
    ...themeObject,
    ...colorTheme
  }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;