import { lazy, Suspense } from 'react';
const Header = lazy(() => import('./components/header/header.component'));
const Mailing = lazy(() => import('./components/mailing/mailing.component'));
const ContactSection = lazy(() => import('./sections/contact.section'));
const Home = lazy(() => import('./sections/home.section'));
const Projects = lazy(() => import('./sections/projects.section'));
const Services = lazy(() => import('./sections/services.section'));

function App() {
  return (
    <>
      <Suspense fallback={<div></div>}>
        <Header />
        <Home />
        <Mailing />
        <Services />
        <Projects />
        <ContactSection />
      </Suspense>
    </>
  );
}

export default App;
